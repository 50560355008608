jQuery(document).ready(function($){

  function addSearchMenu() {
    // Add the Search Icon to the main menu
    let $mainMenu = $('#menu-utility-nav');
    let $searchIcon = $('<li id="search-icon" class="menu-item" tabindex="0"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Vector" d="M13.1539 12.6052L10.6806 10.1519C11.6407 8.95482 12.1056 7.43541 11.9798 5.90608C11.854 4.37675 11.147 2.95374 10.0043 1.92965C8.86151 0.905554 7.36981 0.358223 5.83588 0.400197C4.30196 0.44217 2.84242 1.07026 1.75736 2.15531C0.672308 3.24036 0.0442209 4.69991 0.00224731 6.23383C-0.0397263 7.76775 0.507604 9.25946 1.5317 10.4022C2.55579 11.545 3.9788 12.252 5.50813 12.3778C7.03747 12.5036 8.55687 12.0386 9.75394 11.0785L12.2073 13.5319C12.2692 13.5944 12.343 13.644 12.4242 13.6778C12.5055 13.7117 12.5926 13.7291 12.6806 13.7291C12.7686 13.7291 12.8557 13.7117 12.937 13.6778C13.0182 13.644 13.092 13.5944 13.1539 13.5319C13.2741 13.4076 13.3413 13.2414 13.3413 13.0685C13.3413 12.8957 13.2741 12.7295 13.1539 12.6052ZM6.01393 11.0785C5.09095 11.0785 4.1887 10.8049 3.42127 10.2921C2.65384 9.77929 2.0557 9.05046 1.7025 8.19774C1.34929 7.34502 1.25687 6.40671 1.43694 5.50146C1.617 4.59622 2.06146 3.7647 2.7141 3.11205C3.36675 2.45941 4.19827 2.01495 5.10351 1.83488C6.00876 1.65482 6.94707 1.74724 7.79979 2.10044C8.65251 2.45365 9.38135 3.05179 9.89413 3.81922C10.4069 4.58665 10.6806 5.4889 10.6806 6.41188C10.6806 7.64956 10.1889 8.83654 9.31377 9.71171C8.4386 10.5869 7.25161 11.0785 6.01393 11.0785Z" fill="#FBFBFB"/></svg></li>');
    // Insert Search Icon before the last menu item
    $mainMenu.children().eq(0).before($searchIcon);
  }

  addSearchMenu();
  
  //Show/Hide Search Box
  $("#search-icon").on("click", function() {
    const $searchBox = $('#search-box');
    if ($searchBox.css('display') === 'none') {
        $searchBox.slideDown();
        $("#search-box .gsc-input").focus();
        $("#search-box .gsc-input").focusout(function () {
            $searchBox.slideUp();
        });
    } else {
        $searchBox.slideUp();
    }
    
  });

  $("#search-icon").on("keyup", (event) => {
    if (event.key === "Enter") {
        const $searchBox = $('#search-box');
        if ($searchBox.css('display') === 'none') {
            $searchBox.slideDown();
            $("#search-box .gsc-input").focus();
            $("#search-box .gsc-input").focusout(function () {
                $searchBox.slideUp();
            });
        } else {
            $searchBox.slideUp();
        }
    }
})
});