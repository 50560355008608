import $ from 'jquery';

// Swiper documentation: https://swiperjs.com/swiper-api
import {
    Swiper,
    Navigation,
    Pagination,
    Scrollbar,
    EffectCoverflow,
    Thumbs,
    FreeMode,
    Autoplay
} from 'swiper/swiper.esm.js';

jQuery(document).ready(function() {

    Swiper.use([Navigation, Pagination, Scrollbar, EffectCoverflow, Thumbs, FreeMode, Autoplay]);

    const servicesCardsSwiper = new Swiper('.project-services-cards__slider', {
        direction: 'horizontal',
        loop: false,
        spaceBetween: 20,
        slidesPerView: 1.1,

        navigation: {
            prevEl: '.project-services-arrow-controls__prev',
            nextEl: '.project-services-arrow-controls__next',
        },

        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 1.5,
            },
            // when window width is >= 1024px
            1024: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 3.9,
            },
        },

        on: {
            slideNextTransitionStart: (swiper) => {
                console.log('NEXT');
                $('.project-services-arrow-controls__next').addClass('arrow-control-circled');
                $('.project-services-arrow-controls__prev').removeClass('arrow-control-circled');
            },
            slidePrevTransitionStart: (swiper) => {
                console.log('PREV');
                $('.project-services-arrow-controls__next').removeClass('arrow-control-circled');
                $('.project-services-arrow-controls__prev').addClass('arrow-control-circled');
            }
        }
    });

    const allRecentPostsSwipers = $('.recent-posts__swiper');
    allRecentPostsSwipers.each((i, el) => {
        $(el).addClass(`recent-posts__swiper-${i}`);
        let slidesPerPageDesktop = $(el).attr('data-slides-per-page-desktop');
        let slidesPerPageTablet = $(el).attr('data-slides-per-page-tablet');
        let slidesPerPageMobile = $(el).attr('data-slides-per-page-mobile');

        let recentPostsSwiper = new Swiper('.recent-posts__swiper-' + i, {
            direction: 'horizontal',
            loop: false,
            spaceBetween: 20,
            slidesPerView: slidesPerPageMobile,
    
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
    
            breakpoints: {
                // when window width is >= breakpoint
                768: {
                    slidesPerView: slidesPerPageTablet,
                },
                1025: {
                    slidesPerView: slidesPerPageDesktop,
                },
                // 1200: {
                //     slidesPerView: 2.9,
                // },
            },
        });
    })

    const allLocationsSwipers = $('.all-locations__swiper');
    allLocationsSwipers.each((i, el) => {
        $(el).addClass(`all-locations__swiper-${i}`);
        let slidesPerPageDesktop = $(el).attr('data-slides-per-page-desktop');
        let slidesPerPageTablet = $(el).attr('data-slides-per-page-tablet');
        let slidesPerPageMobile = $(el).attr('data-slides-per-page-mobile');
        let shouldLoop = $(el).attr('data-should-loop');

        let allLocationsSwiper = new Swiper('.all-locations__swiper-' + i, {
            direction: 'horizontal',
            loop: shouldLoop,
            spaceBetween: 20,
            slidesPerView: slidesPerPageMobile,
    
            navigation: {
                prevEl: '.all-locations-arrow-controls__prev',
                nextEl: '.all-locations-arrow-controls__next',
            },
    
            breakpoints: {
                // when window width is >= 768px
                768: {
                    slidesPerView: slidesPerPageTablet,
                },
                // when window width is >= 1024px
                1024: {
                    slidesPerView: slidesPerPageTablet,
                },
                1200: {
                    slidesPerView: 3.8,
                },
                1600: {
                    slidesPerView: slidesPerPageDesktop,
                },
            },
            on: {
                slideNextTransitionStart: (swiper) => {
                    $('.all-locations-arrow-controls__next').addClass('arrow-control-circled');
                    $('.all-locations-arrow-controls__prev').removeClass('arrow-control-circled');
                },
                slidePrevTransitionStart: (swiper) => {
                    $('.all-locations-arrow-controls__next').removeClass('arrow-control-circled');
                    $('.all-locations-arrow-controls__prev').addClass('arrow-control-circled');
                }
            }
        });
    })

    const relatedProjectsSwiper = new Swiper('.single-project__related__swiper', {
        direction: 'horizontal',
        loop: false,
        spaceBetween: 20,
        slidesPerView: 1,

        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },

        breakpoints: {
            // when window width is >= breakpoint
            768: {
                slidesPerView: 2.3,
            },
            1400: {
                slidesPerView: 3.3,
            },
        },
    });

    const relatedPostsSwiper = new Swiper('.single-post__related__swiper', {
        direction: 'horizontal',
        loop: false,
        spaceBetween: 20,
        slidesPerView: 1,

        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },

        breakpoints: {
            // when window width is >= breakpoint
            768: {
                slidesPerView: 2,
            },
            1025: {
                slidesPerView: 3,
            },
        },
    });

    const locationFeaturedProjectsSwiper = new Swiper('.single-location__featured-projects__swiper', {
        direction: 'horizontal',
        loop: false,
        spaceBetween: 20,
        slidesPerView: 1,

        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },

        breakpoints: {
            // when window width is >= breakpoint
            768: {
                slidesPerView: 3,
            },
        },
    });

    const allGallerySwipers = $('.gallery__swiper');
    allGallerySwipers.each((i, el) => {
        $(el).addClass(`gallery__swiper-${i}`);
        $(el).find('.gallery-pagination').addClass(`gallery-pagination-${i}`);
        $(el).find('.gallery-arrow-controls__prev').addClass(`gallery-arrow-controls__prev-${i}`);
        $(el).find('.gallery-arrow-controls__next').addClass(`gallery-arrow-controls__next-${i}`);
        let slidesPerPageDesktop = $(el).attr('data-slides-per-page-desktop');
        let slidesPerPageTablet = $(el).attr('data-slides-per-page-tablet');
        let slidesPerPageMobile = $(el).attr('data-slides-per-page-mobile');
        let navigationType = $(el).attr('data-navigation-type');
        let enableAutoplay = navigationType === 'none' ? true : false;
        let playSpeed = $(el).attr('data-play-speed');

        let pagination = '';
        let navigation = '';
        if (navigationType === 'dots') {
            pagination = {
                el: `.gallery-pagination-${i}`,
                clickable: true,
            }
        } else if (navigationType === 'arrows') {
            navigation = {
                prevEl: `.gallery-arrow-controls__prev-${i}`,
                nextEl: `.gallery-arrow-controls__next-${i}`,
            }
        } else if (navigationType === 'dynamic-dots') {
            pagination = {
                el: `.gallery-pagination-${i}`,
                clickable: true,
                dynamicBullets: true,
            }
        } else {
            pagination = {
                el: `.gallery-pagination-${i}`,
                clickable: true
            }
        }

        let gallerySwiper = new Swiper('.gallery__swiper-' + i, {
            direction: 'horizontal',
            loop: enableAutoplay,
            spaceBetween: 20,
            slidesPerView: slidesPerPageMobile,
            slidesPerGroup: Math.floor(slidesPerPageMobile),
    
            pagination: pagination,

            navigation: navigation,
    
            breakpoints: {
                // when window width is >= breakpoint
                768: {
                    slidesPerView: slidesPerPageTablet,
                    slidesPerGroup: Math.floor(slidesPerPageTablet),
                    pagination: {
                        dynamicBullets: false
                    }
                },
                1025: {
                    slidesPerView: slidesPerPageDesktop,
                    slidesPerGroup: Math.floor(slidesPerPageDesktop)
                }
            },

            on: {
                slideNextTransitionStart: (swiper) => {
                    $('.gallery-arrow-controls__next').addClass('arrow-control-circled');
                    $('.gallery-arrow-controls__prev').removeClass('arrow-control-circled');
                },
                slidePrevTransitionStart: (swiper) => {
                    $('.gallery-arrow-controls__next').removeClass('arrow-control-circled');
                    $('.gallery-arrow-controls__prev').addClass('arrow-control-circled');
                }
            },

            freeMode: enableAutoplay,
            grabCursor: enableAutoplay,
            autoplay: {
                enabled: enableAutoplay,
                delay: 0,
                disableOnInteraction: true
            },
            freeMode: enableAutoplay,
            speed: enableAutoplay ? playSpeed : '300',
            freeModeMomentum: false
        });
    })

    const allTimelineSwipers = $('.timeline__swiper');
    allTimelineSwipers.each((i, el) => {
        $(el).addClass(`timeline__swiper-${i}`);
        let slidesPerPageDesktop = $(el).attr('data-slides-per-page-desktop');
        let slidesPerPageTablet = $(el).attr('data-slides-per-page-tablet');
        let slidesPerPageMobile = $(el).attr('data-slides-per-page-mobile');

        let timelineSwiper = new Swiper('.timeline__swiper-' + i, {
            direction: 'horizontal',
            loop: false,
            spaceBetween: 50,
            slidesPerView: 1,
    
            pagination: {
                el: '.swiper-pagination',
                type: 'progressbar',
                clickable: true
            },

            navigation: {
                prevEl: '.timeline-arrow-controls__prev',
                nextEl: '.timeline-arrow-controls__next',
            },
    
            breakpoints: {
                // when window width is >= breakpoint
                768: {
                    slidesPerView: 1.5,
                    spaceBetween: 100
                },
            },

            on: {
                slideNextTransitionStart: (swiper) => {
                    $('.timeline-arrow-controls__next').addClass('arrow-control-circled');
                    $('.timeline-arrow-controls__prev').removeClass('arrow-control-circled');
                },
                slidePrevTransitionStart: (swiper) => {
                    $('.timeline-arrow-controls__next').removeClass('arrow-control-circled');
                    $('.timeline-arrow-controls__prev').addClass('arrow-control-circled');
                }
            }
        });
    })

    const allTestimonialSwipers = $('.testimonials__swiper');
    allTestimonialSwipers.each((i, el) => {
        $(el).addClass(`testimonials__swiper-${i}`);
        let slidesPerPageDesktop = $(el).attr('data-slides-per-page-desktop');
        let slidesPerPageTablet = $(el).attr('data-slides-per-page-tablet');
        let slidesPerPageMobile = $(el).attr('data-slides-per-page-mobile');

        let testimonialsSwiper = new Swiper('.testimonials__swiper-' + i, {
            direction: 'horizontal',
            loop: false,
            spaceBetween: 20,
            slidesPerView: slidesPerPageMobile,
            slidesPerGroup: Math.floor(slidesPerPageMobile),
    
            pagination: {
                el: '.testimonials-pagination',
                clickable: true
            },
    
            breakpoints: {
                // when window width is >= breakpoint
                768: {
                    slidesPerView: slidesPerPageTablet,
                    slidesPerGroup: Math.floor(slidesPerPageTablet),
                },
                1025: {
                    slidesPerView: slidesPerPageDesktop,
                    slidesPerGroup: Math.floor(slidesPerPageDesktop),
                }
            },
        });
    })
});
