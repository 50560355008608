$(document).ready(function() {
    let teamMemberModal = document.getElementById('team-member-modal');

    if (teamMemberModal) {
        teamMemberModal.addEventListener('show.bs.modal', event => {
            const relatedTarget = event.relatedTarget;
            console.log('target', relatedTarget);
            let title, position, bio, thumbnailUrl, email, linkedin;
            title = relatedTarget.getAttribute('data-bs-title');
            bio = relatedTarget.getAttribute('data-bs-bio');
            thumbnailUrl = relatedTarget.getAttribute('data-bs-thumbnail-url');
            position = relatedTarget.getAttribute('data-bs-position');
            email = relatedTarget.getAttribute('data-bs-email');
            linkedin = relatedTarget.getAttribute('data-bs-linkedin');

            const modalTitle = $('.team-member-modal__title');
            const modalBio = $('.team-member-modal__bio');
            $('.team-member-modal__thumbnail').attr('src', thumbnailUrl);
            const modalPosition = $('.team-member-modal__position');
            
            if (email) {
                $('.team-member-modal__contact--email').show();
                $('.team-member-modal__contact--email').attr('href', 'mailto:' + email);
            } else {
                $('.team-member-modal__contact--email').hide();
            }

            if (linkedin) {
                $('.team-member-modal__contact--linkedin').show();
                $('.team-member-modal__contact--linkedin').attr('href', linkedin);
            } else {
                $('.team-member-modal__contact--linkedin').hide();
            }

            modalTitle.html(title);
            modalBio.html(bio);
            modalPosition.html(position);
        })
    }
})