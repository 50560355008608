jQuery(document).ready(function(){
    //FAQ Dropdowns
    //==================
    $(".accordion").each(function() {
        let accordion = $(this);
        let titleArea = $(this).find(".accordion__item--title");
    
        $(accordion).find(".accordion__item--description-container").hide();
    
        $(titleArea).click(function(){
            $(accordion).find(".accordion__item--description-container").slideUp("slow");
            $(accordion).find(".accordion__item--title .plus").removeClass("minus");
            if($(this).siblings(".accordion__item--description-container")[0].style.display === 'block') {
                $(this).siblings(".accordion__item--description-container").slideUp("slow");
                $(this).find(".plus").removeClass("minus");
            } else if ($(this).siblings(".accordion__item--description-container")[0].style.display === 'none') {
                $(this).siblings(".accordion__item--description-container").slideDown("slow");
                $(this).find(".plus").addClass("minus");
            }
        });
    
        if ($(accordion).hasClass("reveal-first-item")) {
            let firstAccordionItem = $(this).find(".accordion__item").first();
            firstAccordionItem.find(".accordion__item--title").click();
        }
    })
})