/* ==========================================================================
    Standard Multi-level and Mobile Navigation
========================================================================== */

jQuery(document).ready(function($){

    let currentScreenSize = window.screen.width;
    $(window).resize(function () {
        currentScreenSize = window.screen.width;
        setArrowHandling();
    })
    // Menu plus icons
    $('.main-nav li ul').siblings('a').addClass('hasChildren').after('<a class="arrow" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none"><path d="M0.000468193 4.29999C0.0403842 4.10902 0.163375 3.97181 0.300336 3.8385C1.54047 2.63235 2.7786 1.42425 4.01649 0.215913C4.21133 0.0256733 4.4381 -0.0541201 4.70304 0.0378369C4.79859 0.0709223 4.89264 0.13247 4.96499 0.202776C6.24354 1.4442 7.51885 2.68879 8.7939 3.93337C8.98725 4.12216 9.05311 4.3484 8.95631 4.60067C8.8655 4.83762 8.67965 4.97191 8.42044 4.99697C8.21114 5.01716 8.039 4.93518 7.89281 4.79213C7.5872 4.49242 7.2801 4.19416 6.9735 3.89518C6.17419 3.11549 5.37462 2.33604 4.57556 1.55586C4.55286 1.53373 4.53564 1.50672 4.47702 1.46634C4.45955 1.49626 4.44758 1.53154 4.42363 1.55513C3.3227 2.6304 2.22028 3.70446 1.11936 4.77972C0.92826 4.96656 0.708473 5.05 0.442036 4.96899C0.235222 4.90598 0.0982618 4.77024 0.0321512 4.56808C0.0216732 4.53596 0.0109462 4.50385 0.000219299 4.4715L0.000468193 4.29999Z" fill="#FBFBFB"/></svg></a>');

    function setArrowHandling() {
        $('.arrow, a.hasChildren:not([href])').off('click');

        if (currentScreenSize < 1116) {
            $('.arrow, a.hasChildren:not([href])').on("click", function(e) {
                let $arrowEl;
                if ($(e.currentTarget).hasClass('hasChildren')) {
                    $arrowEl = $(this).siblings('a.arrow');
                } else {
                    $arrowEl = $(this);
                }
                let submenuOpen = $arrowEl.hasClass('arrowOpen');
                if (!submenuOpen) {
                    $arrowEl.siblings('.sub-menu').slideDown('fast');
                    $arrowEl.addClass('arrowOpen');
                } else {
                    $arrowEl.siblings('.sub-menu').slideUp('fast');
                    $arrowEl.removeClass('arrowOpen');
                }
                $('.arrow').each(function(el) {
                    let itemWidth = $(this).siblings('.hasChildren').innerWidth();
                    $(this).css('left', `calc(90% - ${itemWidth}px)`);
                })
                e.preventDefault();
            });
        } else {
            $('.arrow').each(function(el) {
                let itemInnerWidth = $(this).siblings('.hasChildren').innerWidth();
                let itemOuterWidth = $(this).parent('li').outerWidth();
                let calculatedLeftPadding = Math.abs((itemOuterWidth - itemInnerWidth) / 2);
                // $(this).css('left', `calc(${itemInnerWidth}px + ${calculatedLeftPadding}px + 8px)`);
                $(this).css('right', '0');
            })
        }
    }
    setArrowHandling();

    $('.menu-item').click(function() {
        $link = $(this).find('a').attr('href');
        if ($link) {
            window.location.href = $link;
        }
    })


    // Mobile menu toggle
    $('.menuToggle').on('click',function(e){
        e.preventDefault();
        $('body').toggleClass('menuOpen');
        $('#search-box').prependTo('.main-nav__links');
        $('.main-nav__links, #search-box').slideToggle('fast');
        $(this).toggleClass('close');
        $('.arrow').each(function(el) {
            let itemWidth = $(this).siblings('.hasChildren').innerWidth();
            $(this).css('left', `calc(90% - ${itemWidth}px)`);
        })
    });

    // Reveal/hide navigation on scroll up/down
    let body = $('body');
    let lastScroll = 0;
    window.addEventListener("scroll", () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= 0) {
            body.removeClass('scrollUp');
        }

        if (!body.hasClass('menuOpen')) {
            if ((currentScroll > lastScroll) && !body.hasClass('scrollDown') && currentScroll > 130) {
                // down
                body.removeClass('scrollUp');
                body.addClass('scrollDown');
            } else if ((currentScroll < lastScroll) && body.hasClass('scrollDown')) {
                // up
                body.removeClass('scrollDown');
                body.addClass('scrollUp');
            }

            lastScroll = currentScroll;
        }
    });

});